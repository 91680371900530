<template>
  <div class="">
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn-primary">
          <i class="fa fa-plus"></i> Refresh
        </button>
      </div>

      <div class="col-auto">
        <button @click="$router.push({
          name:'partners-create'
        })" class="btn btn-primary">
          <i class="fa fa-plus"></i> Add partner
        </button>
      </div>
    </div>
    <br>
    <div class="row align-items-stretch">
      <div
        v-for="(partner, index) in partners"
        :key="index++"
        class="col-3 p-2"
      >
        <div class="card shadow border-0">
          <img :src="partner.logo" class="img-card-top w-50 m-auto" />
          <div class="card-body">
            <div class="row">
              <div class="col-4">Nom :</div>
              <div class="col fw-bold">
                {{ partner.name }}
              </div>
            </div>

            <div class="row d-flex">
              <div class="col-4">Site web :</div>
              <div class="col fw-bold">
                {{ partner.website }}
              </div>
            </div>

            <div class="row">
              <div class="col-4">GSM :</div>
              <div class="col fw-bold">
                {{ partner.phone }}
              </div>
            </div>

            <div class="row">
              <div class="col-4">E-mail :</div>
              <div class="col fw-bold">
                {{ partner.email }}
              </div>
            </div>
            <br>
            <div class="d-flex justify-content-between">
              <button
                @click="
                  $router.push({
                    name: 'partners-edit',
                    params: { id: partner.id },
                  })
                "
                class="btn btn-outline-warning"
              >
                Modifer
              </button>

              <button class="btn btn-outline-danger">Supprimer</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  beforeCreate() {
    this.$store.dispatch("partner/fetch");
  },
  computed: {
    ...mapGetters("partner", { partners: "getPartners" }),
  },
};
</script>
